<template>
  <div>
    <h1 id="msg_info">{{ msg }}</h1><br><br>
    <h1 id="log_info">No One is currently Logged in</h1><br><br>
    <a id="login_link" :href="UI_baseURL + '/login'">Click here to login</a>
    <br>
  </div>
</template>

<script>
import AppConfig from '../../config.js';
export default {
  name: 'UnsignedHome',
  data(){
    return{
      UI_baseURL: AppConfig.UI_Base_URL,
      msg: "Welcome to Rasree Workbook App"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<template>
    <SignedHome v-if="isAuth"></SignedHome>
    <UnsignedHome v-else></UnsignedHome>
</template>

<script>
    import UnsignedHome from './UnsignedHome.vue';
    import SignedHome from './SignedHome.vue';
    import AppConfig from '../../config.js';
    import axios from 'axios';
    //import Keycloak from '../KC_auth';
    export default {
        name: 'Hello',
        components:{
            SignedHome,
            UnsignedHome
        },
        data(){
            return{
                isAuth: false,
                auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
                get_acc_token: "",
                get_id_token: "",
                get_refresh_token: "",
                //login_url: ""
            }
        },
        beforeMount(){
            console.log("in before mount of home")
            let request_url = this.auth_api_Base_URL + '/v1/me'
            //console.log("Base URL", request_url)
            if(localStorage.getItem('access-token') != null){
                this.get_acc_token = localStorage.getItem('access-token')
                this.get_id_token = localStorage.getItem('id-token')
                this.get_refresh_token = localStorage.getItem('refresh-token')
            }
            axios.get(request_url,{
                headers:{
                    Authorization: 'Bearer ' + this.get_acc_token,
                    "id-token": this.get_id_token,
                    "access-token": this.get_acc_token,
                    "refresh-token": this.get_refresh_token,
                }
            }).then(response => {
                console.log(response.data);
                if(response.headers["status_code"] === "200"){
                    this.isAuth = true
                }
                else{
                    this.isAuth = false
                }
            });
        }
    }
</script>

<style >

</style>
